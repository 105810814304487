exports.components = {
  "component---node-modules-onegeo-suite-gatsby-theme-onegeo-src-pages-footer-tsx": () => import("./../../../node_modules/@onegeo-suite/gatsby-theme-onegeo/src/pages/footer.tsx" /* webpackChunkName: "component---node-modules-onegeo-suite-gatsby-theme-onegeo-src-pages-footer-tsx" */),
  "component---node-modules-onegeo-suite-gatsby-theme-onegeo-src-pages-header-tsx": () => import("./../../../node_modules/@onegeo-suite/gatsby-theme-onegeo/src/pages/header.tsx" /* webpackChunkName: "component---node-modules-onegeo-suite-gatsby-theme-onegeo-src-pages-header-tsx" */),
  "component---node-modules-onegeo-suite-gatsby-theme-onegeo-src-pages-index-tsx": () => import("./../../../node_modules/@onegeo-suite/gatsby-theme-onegeo/src/pages/index.tsx" /* webpackChunkName: "component---node-modules-onegeo-suite-gatsby-theme-onegeo-src-pages-index-tsx" */),
  "component---src-onegeo-suite-gatsby-theme-onegeo-pages-news-tsx": () => import("./../../../src/@onegeo-suite/gatsby-theme-onegeo/pages/news.tsx" /* webpackChunkName: "component---src-onegeo-suite-gatsby-theme-onegeo-pages-news-tsx" */),
  "component---src-onegeo-suite-gatsby-theme-onegeo-templates-news-tsx": () => import("./../../../src/@onegeo-suite/gatsby-theme-onegeo/templates/news.tsx" /* webpackChunkName: "component---src-onegeo-suite-gatsby-theme-onegeo-templates-news-tsx" */),
  "component---src-onegeo-suite-gatsby-theme-onegeo-templates-page-tsx": () => import("./../../../src/@onegeo-suite/gatsby-theme-onegeo/templates/page.tsx" /* webpackChunkName: "component---src-onegeo-suite-gatsby-theme-onegeo-templates-page-tsx" */),
  "component---src-pages-400-tsx": () => import("./../../../src/pages/400.tsx" /* webpackChunkName: "component---src-pages-400-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-500-tsx": () => import("./../../../src/pages/500.tsx" /* webpackChunkName: "component---src-pages-500-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-extraction-tsx": () => import("./../../../src/pages/extraction.tsx" /* webpackChunkName: "component---src-pages-extraction-tsx" */),
  "component---src-pages-footer-light-tsx": () => import("./../../../src/pages/footer-light.tsx" /* webpackChunkName: "component---src-pages-footer-light-tsx" */),
  "component---src-pages-organisation-js": () => import("./../../../src/pages/organisation/[...].js" /* webpackChunkName: "component---src-pages-organisation-js" */),
  "component---src-pages-organisations-tsx": () => import("./../../../src/pages/organisations.tsx" /* webpackChunkName: "component---src-pages-organisations-tsx" */),
  "component---src-pages-sample-index-tsx": () => import("./../../../src/pages/sample.index.tsx" /* webpackChunkName: "component---src-pages-sample-index-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-pages-univers-tsx": () => import("./../../../src/pages/univers.tsx" /* webpackChunkName: "component---src-pages-univers-tsx" */),
  "component---src-templates-collection-tsx": () => import("./../../../src/templates/collection.tsx" /* webpackChunkName: "component---src-templates-collection-tsx" */),
  "component---src-templates-services-tsx": () => import("./../../../src/templates/services.tsx" /* webpackChunkName: "component---src-templates-services-tsx" */),
  "component---src-templates-univers-tsx": () => import("./../../../src/templates/univers.tsx" /* webpackChunkName: "component---src-templates-univers-tsx" */)
}

